import React from 'react';
import {render} from 'react-dom';
import * as serviceWorker from './serviceWorker';
// import './assets/scss/style.scss'
import 'popper.js/dist/popper'
import 'bootstrap/dist/js/bootstrap'
import './config/config'
import AppRoutes from "./routes/routes";

render(
  <AppRoutes/>,
  document.getElementById('root')
);
serviceWorker.unregister();
