import React, {Component} from 'react';
import {FormattedMessage} from "react-intl";
import media from '../../../../i18n/media/media.json'

export default class Software extends Component {
  render() {
    return (
      <div className='homeflow--banner'>
        <div className='homeflow--hero bg-transparent'>
          <div className={'container'}>
            <div className={'row'}>
              <div className={'col-md-6 homeflow--home-devices'}>
                <img src={media.enterprise.product} className='app-1 shadow-lg mb-5 mb-lg-0' alt={'workpace'}/>
              </div>
              <div className={'col-md-6 homeflow--home-title'}>
                <div
                  className={'d-flex justify-content-center justify-content-md-start flex-column align-items-center align-items-md-start'}>
                  <h1
                    className='pr-md-4 zoomI header-title-h2-1 animated font-weight-bold text-center text-md-left text-dark'>
                    <FormattedMessage id={'enterprise.section1.title'}/>
                  </h1>

                  <p
                    className="pr-md-5 h6 header-subtitle mt-4 homeflow--gray-description text-center text-md-left text-muted">
                    <b><FormattedMessage id={'loans'}/></b> <FormattedMessage id={'enterprise.section1.subtitle'}/>
                  </p>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    )
  }
}
