import React, {Component} from 'react'
import media from '../../../i18n/media/media.json'
import {FormattedMessage} from "react-intl";
import {assets} from "../../../widget/assets";


export default class HeaderApplications extends Component {

	render() {
		return (
			<div className="position-relative overflow-hidden text-center bg-light animated bounceInLeft">
				<div className='p-3 p-md-5 m-md-3 mt-5 pt-5'>
					<div className="col-md-8 col-xl-8 p-lg-5 mx-auto my-5">
						<h1 className="display-4 font-weight-bold">Homeflow Cobranzas</h1>
						<p className="lead font-weight-normal mt-4">Crea tu espacio de trabajo y lleva un control de tus clientes,
							créditos y cuotas de manera digital, con respaldo en la nube haciendo que tu trabajo fluya de manera
							rápida.</p>

						<div className='input-group mt-5 align-items-center justify-content-center'>
							<a href={media.enterprise.access}
								 rel='noopener noreferrer'
								 target={'_blank'}
								 className="btn btn-google pl-4 pr-4 d-none d-lg-flex">
                <span className="font-weight-bold text-dark">
								<i className="fas fa-plug"/> <FormattedMessage id={'enterprise.header.access'}/> <i
									className="fas fa-long-arrow-right"/>
							</span>
							</a>

							<a
								aria-label={'Homeflow Business'}
								rel="noopener noreferrer"
								className='ml-md-3'
								href='https://play.google.com/store/apps/details?id=com.homeflowenterprise'
								target={'_blank'}>
								<img src={assets(media.home.store)} style={{height: '49px'}} alt="" className="img"/>
							</a>
						</div>
					</div>
					<div className="product-device shadow-sm d-none d-md-block" style={{'--background-image': 'url("/assets/media/img/app/app.jpg")'}}/>
					<div className="product-device product-device-2 shadow-sm d-none d-md-block" style={{'--background-image': 'url("/assets/media/img/app/app.jpg")'}}/>
				</div>
			</div>
		)
	}

}

