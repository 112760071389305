import * as React from 'react'
import {Route, Switch} from 'react-router'
import {BrowserRouter as Router} from 'react-router-dom'
import {IntlProvider} from "react-intl";
import Layout from "../components/layouts/Layout";
import HomePage from "../components/pages/homepage/HomePage";
import Pricing from "../components/pages/pricing/Pricing";
import Enterprise from "../components/pages/enterprise/Enterprise";
import Solutions from "../components/pages/solutions/Solutions";
import translations from "../i18n/translate/translations";
import Page404 from "../components/pages/error/Page404";
import Applications from "../components/pages/cobranzas/Applications";
import Privacy from '../components/pages/privacy/Privacy'
//import {language} from "../widget/functions";

const Context = React.createContext(() => console.error(
	'No TranslationProvider found in component ancestory.'
))

export default function AppRoutes() {
	return (
		<Context.Provider value={'es'}>
			<IntlProvider locale={'es'} defaultLocale={'es'} messages={translations['es']}>
				<Router>
					<Layout>
						<Switch>
							<Route exact path="/"><HomePage/></Route>
							<Route exact path="/apps"><Applications/></Route>
							<Route exact path="/enterprise"><Enterprise/></Route>
							<Route exact path="/solutions"><Solutions/></Route>
							<Route exact path="/pricing"><Pricing/></Route>
							<Route exact path="/privacy"><Privacy/></Route>
							<Route><Page404/></Route>
						</Switch>
					</Layout>
				</Router>
			</IntlProvider>
		</Context.Provider>
	)
};

