import React, {Component} from 'react';
import Navbar from "../../ui/navigation/Navbar";
import HeaderEnterprise from "./HeaderEnterprise";
import {Helmet} from "react-helmet";
import Footer from "../../ui/Footer";
import Luis from './members/Luis'
import Gerardo from './members/Gerardo'
import Mario from "./members/Mario";
import Descriptions from "./descriptions/Descriptions";
import Software from "./modules/Software";
import SoftwareClients from "./modules/SoftwareClients";
import media from '../../../i18n/media/media.json'
import translations from '../../../i18n/translate/translations'
import {FormattedMessage} from "react-intl";
import {language} from "../../../widget/functions";

export default class Enterprise extends Component {

	componentDidMount() {
		window.addEventListener('DOMContentLoaded', () => {
			document.getElementById('preloader').classList.add('d-none')
		});
	}

	render() {
		return (
			<div className={'page-content-site'}>
				<Helmet>
					<title>{translations[language()]['enterprise.title']}</title>
					<link rel="canonical" href="https://homeflowapp.com/enterprise"/>
					<link rel="alternate" hrefLang="x-default" href="https://homeflowapp.com/enterprise"/>
					<body className={'path-enterprise'}/>
				</Helmet>

				<Navbar/>
				<HeaderEnterprise/>
				<Software/>
				<SoftwareClients/>

				<div className='container-fluid'>
					<div className='container mt-5 pt-5 pb-5 border-top'>
            <h1 className='font-weight-bold header-title-h2 text-center w-100 mb-5'>
              <FormattedMessage id={'team.title'}/> <strong className='text-primary'><FormattedMessage id={'app'}/></strong>
            </h1>
						<div className="row justify-content-center">
							<Luis/>
							<Gerardo/>
							<Mario/>
						</div>
					</div>
				</div>

				<hr/>

				<div className='container-fluid mb-3 mt-4'>
					<div className='container'>
						<h1 className='mx-4 mx-lg-0 font-weight-bold text-center section-title'>
							<FormattedMessage id={'enterprise.fiability'}/>
						</h1>
						<div className='row justify-content-center mt-5'>
							<div className='col-md-6 col-lg-3 d-flex justify-content-center mb-5'>
								<div className="card border-0" style={{width: "18rem"}}>
									<img src={media.enterpriseadd.crediagil} className='w-100 card-circle-img mx-auto'
									     alt="..."/>
								</div>
							</div>

							<div className='col-md-6 col-lg-3 d-flex justify-content-center mb-5'>
								<div className="card border-0" style={{width: "18rem"}}>
									<img src={media.enterpriseadd.faztcredit} className='w-100 card-circle-img mx-auto'
									     alt="..."/>
								</div>
							</div>

							<div className='col-md-6 col-lg-3 d-flex justify-content-center mb-5'>
								<div className="card border-0" style={{width: "18rem"}}>
									<img src={media.enterpriseadd.chdesign} className='card-circle-img mx-auto' alt="..."/>
								</div>
							</div>

							<div className='col-md-6 col-lg-3 d-flex justify-content-center mb-4'>
								<div className="card border-0" style={{width: "18rem"}}>
									<img src={media.enterpriseadd.kenota} className='card-circle-img mx-auto'
									     alt="..."/>
								</div>
							</div>

						</div>
					</div>
				</div>

				<Descriptions/>
				<Footer/>
			</div>
		);
	}
}

