import React, {Component} from 'react';
import Navbar from "../../ui/navigation/Navbar";
import {Helmet} from "react-helmet";
import Footer from "../../ui/Footer";
import translations from '../../../i18n/translate/translations';
import {language} from "../../../widget/functions";
import Software from "../enterprise/modules/Software";
import SoftwareClients from "../enterprise/modules/SoftwareClients";
import HeaderApplications from "./HeaderApplications";
import DescriptionApp from "./app/DescriptionApp";

export default class Applications extends Component {

	componentDidMount() {
		window.addEventListener('DOMContentLoaded', () => {
			document.getElementById('preloader').classList.add('d-none')
		});
	}

	render() {
		return (
			<div className={'page-content-site'}>
				<Helmet>
					<title>{translations[language()]['apps.title']} - Homeflow</title>
					<link rel="canonical" href="https://homeflowapp.com/enterprise"/>
					<link rel="alternate" hrefLang="x-default" href="https://homeflowapp.com/enterprise"/>
					<body className={'path-enterprise'}/>
				</Helmet>

				<Navbar/>
				<HeaderApplications/>
				<Software/>
				<SoftwareClients/>
				<DescriptionApp/>
				<Footer/>
			</div>
		);
	}
}

