import React, { Component } from 'react'
import media from '../../../i18n/media/media.json'
import {FormattedMessage} from "react-intl";

export default class HeaderEnterprise extends Component {

  render () {
    return (
      <div className='homeflow--banner border-bottom homeflow--tea'>
        <div className='homeflow--hero bg-transparen'>
          <div className={'container'}>
            <div className={'row flex-lg-row-reverse mt-4'}>
              <div className={'col-md-6 homeflow--home-devices my-auto'}>
                <img src={media.enterprise.header} className='img-header mt-5 mt-lg-0 mr-lg-5' alt={'workpace'}/>
              </div>
              <div className={'col-md-6 homeflow--home-title'}>
                <div className={'homeflow--home-btn d-flex justify-content-center justify-content-md-start flex-column align-items-center align-items-md-start'}>
                  <h1 className='pr-md-4 zoomI header-title-h2 animated font-weight-bold text-center text-md-left text-dark'>
                    <FormattedMessage id={'enterprise.header.title'}/>
                  </h1>

                  <p className="pr-md-5 h6 header-subtitle mt-4 homeflow--gray-description text-center text-md-left text-muted">
                    <FormattedMessage id={'enterprise.header.subtitle'}/>
                  </p>

                  <a
                    href={media.enterprise.access}
                    rel='noopener noreferrer'
                    target={'_blank'}
                    className="btn btn-google mt-5 pl-4 pr-4 mb-5">
                    <span className="font-weight-bold text-dark">
                      <i className="fas fa-plug"/> <FormattedMessage id={'enterprise.header.access'}/> <i className="fas fa-long-arrow-right"/>
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

}

