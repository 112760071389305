import React, {Component} from 'react';
import Body from "../content/Body";
import PropTypes from "prop-types";

export default class Layout extends Component {
	static propTypes = {
		children: PropTypes.object.isRequired
	};

	render() {
		const {children} = this.props;
		return (
			<div className={'homeflow-app'}>
				<Body body={children}/>
			</div>
		);
	}
}
