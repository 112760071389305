import React, {Component} from "react"
import Navbar from "../../ui/navigation/Navbar";
import Footer from "../../ui/Footer";
import Header from "./Header";
import {Helmet} from "react-helmet";
import media from '../../../i18n/media/media.json'
import translations from '../../../i18n/translate/translations'
import {FormattedMessage} from "react-intl";
import {language} from "../../../widget/functions";
import {assets} from "../../../widget/assets";
import Luis from "../enterprise/members/Luis";
import Empresas from "../enterprise/empresas";

export default class HomePage extends Component {

  componentDidMount() {
    window.addEventListener('DOMContentLoaded', () => {
      document.getElementById('preloader').classList.add('d-none')
    });
  }

  render() {
    return (
      <div className={'path-login w-100'}>
        <Helmet>
          <title>{translations[language()]['homepage.title']}</title>
          <link rel="canonical" href="https://homeflowapp.com"/>
          <link rel="alternate" hrefLang="x-default" href="https://homeflowapp.com/"/>
          <body className='path-home'/>
        </Helmet>

        <Navbar/>
        <Header/>

        <div className='container-fluid mb-5 pb-5 mt-5'>
          <div className='container'>
            <div className='row'>
              <div className={'col-lg-5 homeflow--home-devices my-auto'}>
                <img src={assets(media.home.why)} className='w-100 mb-5 mb-lg-0 why-img' alt={'workpace'}/>
              </div>

              <div className='col-lg-7 my-auto'>
                <div className='p-lg-3 text-center'>
                  <h1 className='font-weight-bold text-center pt-2 w-100 section-title'>¿Qué es Homeflow?</h1>
                  <p className='header-subtitle hf-text-gray mt-3 homeflow--gray-description text-center'>
                    <FormattedMessage id={'homepage.section1.why'}/>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Empresas/>

        <div className='container-fluid mt-5 mb-3'>
          <div className='container pb-2'>
            <div className="row justify-content-center">
              <div className='row justify-content-center'>
                <h1 className='font-weight-bold section-title text-center mt-3'>
                  <FormattedMessage id={'homepage.section2.title'}/>
                </h1>

                <p className='header-subtitle px-4 hf-text-gray mt-3 text-center w-75'>
                  <FormattedMessage id={'homepage.section2.subtitle'}/>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className='container-fluid mb-5 mt-3'>
          <div className='container'>
            <div className='row justify-content-center'>
              <div className='col-md-12 col-lg-6 d-flex justify-content-center mb-5'>
                <div className="card border-0 card-deck">
                  <img src={assets(media.home.search)} className="card-img-top hf-card-min-height w-75 mx-auto" alt="..."/>
                  <div className="card-body p-t-0">
                    <h1 className='card-title font-weight-700 text-center w-100 header-title-h2-1'>Recaudaci&oacute;n</h1>
                    <p className="card-text header-subtitle hf-text-gray mt-3 homeflow--gray-description text-center">
                     <FormattedMessage id={'homepage.section1.search'}/>
                    </p>
                  </div>
                </div>
              </div>

              <div className='col-md-12 col-lg-6 d-flex justify-content-center mb-5'>
                <div className="card border-0 card-deck">
                  <img src={assets(media.home.credits)} className="card-img-top hf-card-min-height w-75 mx-auto" alt="..."/>
                  <div className="card-body pt-0">
                    <h1 className='card-title font-weight-700 text-center w-100 header-title-h2-1'>Cr&eacute;ditos</h1>
                    <p className="card-text header-subtitle hf-text-gray homeflow--gray-description text-center">
                      <FormattedMessage id={'homepage.section1.credits'}/>
                    </p>
                  </div>
                </div>
              </div>

              <div className='col-md-12 col-lg-6 d-flex justify-content-center mb-5'>
                <div className="card border-0 card-deck">
                  <img src={assets(media.home.responsive)} className="card-img-top hf-card-min-height w-75 mx-auto" alt="..."/>
                  <div className="card-body p-0">
                    <h1 className='card-title font-weight-700 text-center w-100 header-title-h2-1'>Usabilidad</h1>
                    <p className="card-text header-subtitle hf-text-gray mt-3 text-center">
                      <FormattedMessage id={'homepage.cards.card1'}/>
                    </p>
                  </div>
                </div>
              </div>

              <div className='col-md-12 col-lg-6 d-flex justify-content-center mb-5'>
                <div className="card border-0 card-deck">
                  <img src={assets(media.home.react)} className="card-img-top hf-card-min-height mx-auto w-75" alt="..."/>
                  <div className="card-body p-0">
                    <h1 className='card-title font-weight-700 text-center w-100 header-title-h2-1'>Reportes</h1>
                    <p className="card-text header-subtitle hf-text-gray mt-3 text-center">
                      <FormattedMessage id={'homepage.cards.card2'}/>
                    </p>
                  </div>
                </div>
              </div>

              <div className='col-md-6 col-lg-6 d-flex justify-content-center mb-5'>
                <div className="card border-0 card-deck">
                  <img src={assets(media.home.server)} className="card-img-top hf-card-min-height w-75 mx-auto" alt="..."/>
                  <div className="card-body p-0">
                    <h1 className='card-title font-weight-700 text-center w-100 header-title-h2-1'>Respaldos</h1>
                    <p className="card-text header-subtitle hf-text-gray mt-3 text-center">
                      <FormattedMessage id={'homepage.cards.card3'}/>
                    </p>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>

         <div className='container-fluid mb-5 pb-5 mt-5'>
          <div className='container'>
            <hr className='mb-5 pb-5'/>
            <Luis/>
          </div>
        </div>

        {/*<Descriptions/>*/}

        <Footer/>
      </div>
    )
  }

}
