import React, { Component } from 'react'
import {FormattedMessage} from "react-intl";
import media from '../../../i18n/media/media.json'
import {assets} from "../../../widget/assets";

export default class HeaderSolutions extends Component {

  render () {
    return (
      <div className='homeflow--banner border-bottom pb-5'>
        <div className='homeflow--hero bg-transparent'>
          <div className={'container'}>
            <div className='row flex-lg-row-reverse'>
              <div className={'col-md-6 homeflow--home-devices d-md-block my-auto'}>
                <img src={assets(media.solutions.header)} className='mr-lg-5 mt-5 img-header' alt={'Develop'}/>
              </div>

              <div className={'col-md-6 homeflow--home-title'}>
                <div className={'homeflow--home-btn d-flex justify-content-center justify-content-md-start flex-column align-items-center align-items-md-start'}>
                  <h1 className='pr-md-4 zoomI header-title-h2 mt-5 mt-md-0 animated font-weight-bold text-center text-md-left text-dark'>
                    <FormattedMessage id={'solutions.header.title'}/>
                  </h1>

                  <p className="pr-md-5 h6 header-subtitle mt-4 homeflow--gray-description text-center text-md-left text-muted">
                    <FormattedMessage id={'solutions.header.subtitle'}/>
                  </p>

                  <a href={'mailto:support@homeflowapp.com'} rel="noopener noreferrer" className="btn btn-google mt-5 pl-5 pr-5 mb-5">
                    <span className="font-weight-bold text-dark">
                      <i className="fad fa-envelope-open fa-lg pr-2 text-primary"/> <FormattedMessage id={'solutions.header.access'}/> <i className="fas fa-long-arrow-right text-primary"/>
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

}

