import flatten from 'flat'
import {APP_LOCALES} from './languages';

const translations = [];
Object.keys(APP_LOCALES).forEach((key) => {
  try {
    translations[key] = flatten(require(`../locales/${key}.json`));
  } catch (err) {
    console.warn(`Can't find translations for ${key}`);
  }
});

export default translations;
