import React, {Component} from 'react';
import media from '../../../../i18n/media/media.json'
import {FormattedMessage} from "react-intl";

export default class SoftwareClients extends Component {
	render() {
		return (
			<div className='homeflow--banner mt-4'>
				<div className='homeflow--hero bg-transparent'>
					<div className={'container'}>
						<div className={'row flex-lg-row-reverse'}>
							<div className={'col-md-6 homeflow--home-devices my-auto'}>
								<img src={media.enterprise.quality} className='app-2 mb-5 mb-lg-0' alt={'workpace'}/>
							</div>

							<div className={'col-md-6 homeflow--home-title'}>
								<div className={'d-flex justify-content-center justify-content-md-start flex-column align-items-center align-items-md-start'}>
									<h1
										className='pr-md-4 zoomI header-title-h2-1 animated font-weight-bold text-center text-md-left text-dark'>
										<FormattedMessage id={'enterprise.section2.title'}/>
									</h1>

									<p
										className="pr-md-5 h6 header-subtitle mt-4 homeflow--gray-description text-center text-md-left text-muted">
										<FormattedMessage id={'enterprise.section2.subtitle'}/>
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}
