//Assets
import {server_api} from "../config/config";

export const capitalize = (s) => {
	if (typeof s !== 'string') return '';
	return s.charAt(0).toUpperCase() + s.slice(1);
};

export const assets = path => {
	return `${server_api}/assets${path}`;
};

export const route = path => {
	return server_api + path;
};

export const url = path => {
	return server_api + path;
};
