import React, {Component} from 'react';
import {FormattedMessage} from "react-intl";
import media from "../../../i18n/media/media.json";
import {assets} from "../../../widget/assets";

export default class Empresas extends Component {
	render() {
		return (
			<div className='container-fluid mb-5 mt-5'>
				<div className='container text-center '>
					<h1 className='mx-4 mx-lg-0 font-weight-bold text-center section-title'>
						<FormattedMessage id={'enterprise.fiability'}/>
					</h1>
					<p className="text-center header-subtitle">
            Todas estas empresas aumentaron su productividad y seguridad
            <br/>
            instantaneamente al utilizar nuestros servicios.
          </p>
					<div className='row justify-content-center mt-5'>
						<div className='col-md-6 col-lg-3 d-flex justify-content-center mb-5' style={{height: '200px'}}>
							<div className="border-0 card-circle-img" style={{ background: `url(${assets('/media/img/app/crediagi.png')})`}}></div>
						</div>

            <div className='col-md-6 col-lg-3 d-flex justify-content-center mb-5' style={{height: '200px'}}>
							<div className="p-3 card-circle-img" style={{ background: `url(${assets('/media/img/app/cexpress.png')})`}}></div>
						</div>

            <div className='col-md-6 col-lg-3 d-flex justify-content-center mb-5' style={{height: '200px'}}>
							<div className="p-3 card-circle-img" style={{ background: `url(${assets('/media/img/app/faztcredit.jpeg')})`}}></div>
						</div>

						{/*<div className='col-md-6 col-lg-3 d-flex justify-content-center align-items-center mb-5' style={{height: '200px'}}>
							<div className="p-3">
								<img src={media.enterpriseadd.faztcredit} className='w-100 card-circle-img mx-auto' alt="..."/>
							</div>
						</div>*/}

						<div className='col-md-6 col-lg-3 d-flex justify-content-center align-items-center mb-4' style={{height: '200px'}}>
							<div className="p-3 card-circle-img" style={{ background: `url(${assets(media.home.creditcash)})`}}></div>
						</div>

						<div className='col-md-6 col-lg-3 d-flex justify-content-center align-items-center mb-4' style={{height: '200px'}}>
							<div className="p-3 card-circle-img" style={{ background: `url(${assets('/media/img/app/kenota.jpg')})`}}></div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
