import React, {Component} from 'react';
import media from '../../../../i18n/media/media.json'
import {FormattedMessage} from "react-intl";

export default class Mario extends Component {

  render() {
    return (
      <div className='col-md-10 mt-3'>
        <div className="card mb-3 homeflow-card">
          <div className="row no-gutters">
            <div className="col-lg-3 user-photo-enterprise-members my-auto">
              <img src={media.team.mario} className="card-img mt-5 mt-lg-0" alt="..."/>
            </div>
            <div className="col-md-9">
              <div className="card-body">
                <h3 className="card-title font-weight-bold text-center text-md-left">
                  <FormattedMessage id={'team.mario.name'}/>
                </h3>
                <h6 className="card-subtitle mb-2 hf-text-blue text-center text-md-left">
                  <FormattedMessage id={'team.mario.role'}/>
                </h6>
                <p className="card-text header-subtitle-2 hf-text-gray mt-3 text-justify" style={{fontSize: '0.9rem'}}>
                  <FormattedMessage id={'team.mario.description'}/>
                </p>

                <ul className="nav">
                  <li className="nav-item">
                    <a className="nav-link" href="https://www.linkedin.com/in/mario-talavera-a8615811b" target='_blank'
                       rel="noopener noreferrer">
                      <i className='fab fa-linkedin fa-2x hf-text-blue'/>
                    </a>
                  </li>

                  <li className="nav-item">
                    <a className="nav-link" href="mailto:matalaflores@gmail.com" target='_blank' rel="noopener noreferrer">
                      <i className='fad fa-envelope-open fa-2x hf-text-blue'/>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

