import React, {Component} from 'react';
import Navbar from "../../ui/navigation/Navbar";
import {Helmet} from "react-helmet";
import Footer from "../../ui/Footer";
import ReactMarkdown from 'react-markdown'
import gfm from 'remark-gfm'
import translations from "../../../i18n/translate/translations";
import {language} from "../../../widget/functions";
import {FormattedMessage} from "react-intl";
import HeaderPricing from '../pricing/HeaderPricing'

const markdown = `A paragraph with *emphasis* and **strong importance**.

> A block quote with ~strikethrough~ and a URL: https://reactjs.org.

* Lists
* [ ] todo
* [x] done

A table:

| a | b |
| - | - |
`
export default class Privacy extends Component {

	componentDidMount() {
		window.addEventListener('DOMContentLoaded', () => {
			document.getElementById('preloader').classList.add('d-none')
		});
	}

	render() {
		return (
			<div className={'page-content-site path-pricing'}>
				<Helmet>
					<title>{translations[language()]['price.title']}</title>
					<link rel="canonical" href="https://homeflowapp.com/pricing"/>
					<link rel="alternate" hrefLang="x-default" href="https://homeflowapp.com/pricing"/>
					<body className='path-pricing'/>
				</Helmet>

				<Navbar/>
				<HeaderPricing/>
				<ReactMarkdown remarkPlugins={[gfm]} children={markdown} />


				<div className='container-fluid mt-5 pt-1 mb-3 path-pricing-content'>
					<div className="container">
						<h1 className='text-center font-weight-bold header-title mb-4'>
							<FormattedMessage id={'price.plan.title'}/>
						</h1>
						<div className="row mb-3 justify-content-center">
							<div className="mb-4 col-md-6 col-lg-4">
								<div className='homeflow-card text-center m-lg-4'>
									<div className="card-header">
										<h4 className="my-0 font-weight-bold">
											<FormattedMessage id={'price.plan.gratis.name'}/>
										</h4>
									</div>
									<div className="card-body">
										<h1 className="card-title pricing-card-title hf-text-blue display-4">
											<FormattedMessage id={'price.plan.gratis.price'}/>
										</h1>
										<p className="text-dark font-weight-700">
											<FormattedMessage id={'price.plan.gratis.time'}/>
										</p>

										<div className='justify-content-center d-flex'>
											<ul className="list-unstyled mt-3 mb-4 text-left">
												<li><i className={'far fa-check hf-blue'}/> <FormattedMessage id={'price.plan.gratis.route'}/></li>
												<li><i className={'far fa-check hf-blue'}/> <FormattedMessage id={'price.plan.gratis.clients'}/></li>
												<li><i className={'far fa-check hf-blue'}/> <FormattedMessage id={'price.details.credits'}/></li>
												<li><i className={'far fa-check hf-blue'}/> <FormattedMessage id={'price.details.quotes'}/></li>
												<li><i className={'far fa-check hf-blue'}/> <FormattedMessage id={'price.details.movil'}/></li>
											</ul>
										</div>
									</div>

									<div className="card-footer bg-white">
										<button type="button" className="btn btn-lg btn-block btn-outline-primary font-weight-bold">
											<FormattedMessage id={'price.plan.gratis.create'}/> <i className="fas fa-long-arrow-right fa-lg ml-2"/>
										</button>
									</div>
								</div>
							</div>
							<div className="mb-4 col-md-6 col-lg-4">
								<div className='homeflow-card text-center m-lg-4'>
									<div className="card-header">
										<h4 className="my-0 font-weight-bold">
											<FormattedMessage id={'price.plan.standard.name'}/>
										</h4>
									</div>
									<div className="card-body">
										<h1 className="card-title pricing-card-title hf-text-blue display-4">
											<FormattedMessage id={'price.plan.standard.price'}/>
										</h1>
										<p className="text-dark font-weight-700">
											<FormattedMessage id={'price.plan.standard.time'}/>
										</p>

										<div className='justify-content-center d-flex'>
											<ul className="list-unstyled mt-3 mb-4 text-left">
												<li><i className={'far fa-check hf-blue'}/> <FormattedMessage id={'price.plan.standard.route'}/></li>
												<li><i className={'far fa-check hf-blue'}/> <FormattedMessage id={'price.plan.standard.clients'}/></li>
												<li><i className={'far fa-check hf-blue'}/> <FormattedMessage id={'price.details.credits'}/>
												</li>
												<li><i className={'far fa-check hf-blue'}/> <FormattedMessage id={'price.details.quotes'}/></li>
												<li><i className={'far fa-check hf-blue'}/> <FormattedMessage id={'price.details.movil'}/></li>
											</ul>
										</div>
									</div>

									<div className="card-footer bg-white">
										<button type="button" className="btn btn-lg btn-block btn-outline-primary font-weight-bold">
											<FormattedMessage id={'price.plan.standard.create'}/> <i className="fas fa-long-arrow-right fa-lg ml-2"/>
										</button>
									</div>
								</div>
							</div>
							<div className="mb-4 col-md-6 col-lg-4">
								<div className='homeflow-card text-center m-lg-4'>
									<div className="card-header">
										<h4 className="my-0 font-weight-bold">
											<FormattedMessage id={'price.plan.plus.name'}/>
										</h4>
									</div>
									<div className="card-body">
										<h1 className="card-title pricing-card-title hf-text-blue display-4">
											<FormattedMessage id={'price.plan.plus.price'}/>
										</h1>
										<p className="text-dark font-weight-700">
											<FormattedMessage id={'price.plan.plus.time'}/>
										</p>

										<div className='justify-content-center d-flex'>
											<ul className="list-unstyled mt-3 mb-4 text-left">
												<li><i className={'far fa-check hf-blue'}/> <FormattedMessage id={'price.plan.plus.route'}/></li>
												<li><i className={'far fa-check hf-blue'}/> <FormattedMessage id={'price.plan.plus.clients'}/>
												</li>
												<li><i className={'far fa-check hf-blue'}/> <FormattedMessage id={'price.details.credits'}/>
												</li>
												<li><i className={'far fa-check hf-blue'}/> <FormattedMessage id={'price.details.quotes'}/></li>
												<li><i className={'far fa-check hf-blue'}/> <FormattedMessage id={'price.details.movil'}/></li>
											</ul>
										</div>
									</div>

									<div className="card-footer bg-white">
										<button type="button" className="btn btn-lg btn-block btn-outline-primary font-weight-bold">
											<FormattedMessage id={'price.plan.plus.create'}/> <i className="fas fa-long-arrow-right fa-lg ml-2"/>
										</button>
									</div>
								</div>
							</div>
							<div className="mb-4 col-md-6 col-lg-4">
								<div className='homeflow-card text-center m-lg-4'>
									<div className="card-header">
										<h4 className="my-0 font-weight-bold">
											<FormattedMessage id={'price.plan.premium.name'}/>
										</h4>
									</div>
									<div className="card-body">
										<h1 className="card-title pricing-card-title hf-text-blue display-4">
											<FormattedMessage id={'price.plan.premium.price'}/>
										</h1>
										<p className="text-dark font-weight-700">
											<FormattedMessage id={'price.plan.premium.time'}/>
										</p>

										<div className='justify-content-center d-flex'>
											<ul className="list-unstyled mt-3 mb-4 text-left">
												<li><i className={'far fa-check hf-blue'}/> <FormattedMessage id={'price.plan.premium.route'}/></li>
												<li><i className={'far fa-check hf-blue'}/> <FormattedMessage
													id={'price.plan.premium.clients'}/></li>
												<li><i className={'far fa-check hf-blue'}/> <FormattedMessage id={'price.details.credits'}/>
												</li>
												<li><i className={'far fa-check hf-blue'}/> <FormattedMessage id={'price.details.quotes'}/></li>
												<li><i className={'far fa-check hf-blue'}/> <FormattedMessage id={'price.details.movil'}/></li>
											</ul>
										</div>
									</div>

									<div className="card-footer bg-white">
										<button type="button" className="btn btn-lg btn-block btn-outline-primary font-weight-bold">
											<FormattedMessage id={'price.plan.premium.create'}/> <i className="fas fa-long-arrow-right fa-lg ml-2"/>
										</button>
									</div>
								</div>
							</div>
							<div className="mb-4 col-md-6 col-lg-4">
								<div className='homeflow-card text-center m-lg-4'>
									<div className="card-header">
										<h4 className="my-0 font-weight-bold">
											<FormattedMessage id={'price.plan.enterprise.name'}/>
										</h4>
									</div>
									<div className="card-body">
										<h1 className="card-title pricing-card-title hf-text-blue display-4">
											<FormattedMessage id={'price.plan.enterprise.price'}/>
										</h1>
										<p className="text-dark font-weight-700">
											<FormattedMessage id={'price.plan.enterprise.time'}/>
										</p>

										<div className='justify-content-center d-flex'>
											<ul className="list-unstyled mt-3 mb-4 text-left">
												<li><i className={'far fa-check hf-blue'}/> <FormattedMessage id={'price.plan.enterprise.route'}/></li>
												<li><i className={'far fa-check hf-blue'}/> <FormattedMessage id={'price.details.clients'}/></li>
												<li><i className={'far fa-check hf-blue'}/> <FormattedMessage id={'price.details.credits'}/>
												</li>
												<li><i className={'far fa-check hf-blue'}/> <FormattedMessage id={'price.details.quotes'}/></li>
												<li><i className={'far fa-check hf-blue'}/> <FormattedMessage id={'price.details.graphic'}/>
												</li>
												<li><i className={'far fa-check hf-blue'}/> <FormattedMessage id={'price.details.reports'}/>
												</li>
												<li><i className={'far fa-check hf-blue'}/> <FormattedMessage id={'price.details.movil'}/></li>
											</ul>
										</div>
									</div>

									<div className="card-footer bg-white">
										<button type="button" data-toggle="modal" data-target="#exampleModal" data-whatever="@fat"
										        className="btn btn-lg btn-block btn-primary font-weight-bold">
											Contactanos
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<Footer/>
			</div>
		);
	}
}

