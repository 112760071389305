import dateformat from "dateformat";

dateformat.i18n = {
	dayNames: [
		'Dom', 'Lun', 'Mar', 'Mier', 'Jue', 'Vie', 'Sab',
		'Domingo', 'Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sabado',
	],
	monthNames: [
		'Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic',
		'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
	],
	timeNames: [
		'a', 'p', 'am', 'pm', 'A', 'P', 'AM', 'PM'
	]
};

export const dateToday = () => {
	let now = new Date();
	return dateformat(now, "dddd, d 'de' mmmm 'del' yyyy");
};

export const today = () => {
	let now = new Date();
	return dateformat(now, "d'/'mm'/'yyyy");
};

export const year = () => {
	let now = new Date();
	return dateformat(now, "yyyy");
};

export const moneyNI = (quantity) => {
	const formatter = new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: 'USD',
		minimumFractionDigits: 2,
	});

	return `C${formatter.format(quantity)}`
};

export const language = () => {
	return 'es';
};


