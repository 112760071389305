import React, {Component} from 'react'

export default class DescriptionApp extends Component {
	render() {
		return (
			<div className='bg-white mt-5'>
				<div className='row justify-content-center'>
					<div className='col-12 mb-4 mt-5'>
						<h1 className="font-weight-bold text-center">Interactúa de manera facil</h1>
						<p className="lead text-center">Lleva el control de tu clientes y creditos de forma interactiva y eficiente.</p>
					</div>
					<div className="d-md-flex flex-md-equal my-md-3 pl-md-3 justify-content-center">
						<div className="hf-card-blue hf-card-round-20 mr-md-3 pt-3 px-3 pt-md-5 text-white text-center overflow-hidden mb-5">
							<div className="my-3 py-3">
								<h2 className="display-5 font-weight-bold text-white">Recaudado</h2>
								<p className="lead">Visualiza lo recaudado en tiempo real </p>
							</div>
							<div className="shadow-sm mx-auto hf-card-phone hf-card-phone-1"/>
						</div>

						<div className="bg-light hf-card-round-20 mr-md-3 pt-3 px-3 pt-md-5 text-center overflow-hidden mb-5">
							<div className="my-3 p-3">
								<h2 className="display-5 font-weight-bold">Cr&eacute;ditos</h2>
								<p className="lead">Superviza el estado de los creditos.</p>
							</div>
							<div className="shadow-sm mx-auto hf-card-phone hf-card-phone-2"/>
						</div>

						<div className="bg-gray-darkest hf-card-round-20 mr-md-3 pt-3 px-3 pt-md-5 text-white text-center overflow-hidden mb-5">
							<div className="my-3 py-3">
								<h2 className="display-5 font-weight-bold text-white">Mis Clientes</h2>
								<p className="lead">Acceso a todos tus clientes en una sola pantalla.</p>
							</div>
							<div className="shadow-sm mx-auto hf-card-phone hf-card-phone-3"/>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

