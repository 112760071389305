import React, { Component } from 'react'
import media from '../../../i18n/media/media.json'
import {FormattedMessage} from "react-intl";
import {assets} from "../../../widget/assets";

export default class HeaderPricing extends Component {

  render () {
    return (
      <div className='homeflow--banner'>
        <div className='homeflow--hero'>
          <div className={'container'}>
            <div className={'row flex-lg-row-reverse'}>
              <div className={'col-md-6 homeflow--home-devices my-auto'}>
                <img src={assets(media.price.header)} className="img-header mt-5 mt-lg-0" alt="..."/>
              </div>
              <div className={'col-md-6 homeflow--home-title'}>
                <div className='homeflow--home-btn d-flex justify-content-center justify-content-md-start flex-column align-items-center align-items-md-start'>
                  <h1 className='pr-md-4 mt-lg-5 header-title mt-md-3 zoomIn animated font-weight-bold text-dark text-center text-md-left'>
                    <FormattedMessage id={'price.header.title'}/>
                  </h1>

                  <p className="pr-md-5 header-subtitle text-muted mt-3 text-center text-md-left">
                    <FormattedMessage id={'price.header.subtitle'}/>
                  </p>
                  <p className={'mt-5 text-center w-100'}>
                    <i className='fad fa-arrow-down fa-4x text-primary animate-up-to-down'/>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

}
