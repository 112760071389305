import React, {Component} from 'react';
import {FormattedMessage} from "react-intl";
import {assets} from "../../../../widget/assets";

export default class Luis extends Component {

  render() {
    return (
      <div className="card mb-3 homeflow-card">
          <div className="row no-gutters">
            <div className="col-lg-3 user-photo-enterprise-members my-auto">
              <img src={assets('/media/img/team/luis.jpeg')} className="card-img mt-5 mt-lg-0" alt="..."/>
            </div>
            <div className="col-md-9">
              <div className="card-body">
                <h3 className="card-title font-weight-bold text-center text-md-left">
                  <FormattedMessage id={'team.luis.name'}/>
                </h3>
                <h6 className="card-subtitle mb-2 hf-text-blue text-center text-md-left">
                  <FormattedMessage id={'team.luis.role'}/>
                </h6>
                <p className="card-text header-subtitle-2 hf-text-gray mt-3 " style={{fontSize: '1rem'}}>
                  <FormattedMessage id={'team.luis.description'}/>
                </p>

                <ul className="nav">
                  <li className="nav-item">
                    <a className="nav-link" href="https://www.facebook.com/Slzno1" target='_blank'
                       rel="noopener noreferrer">
                      <i className='fab fa-facebook fa-2x hf-text-blue'/>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="https://twitter.com/SlznoLudav" target='_blank'
                       rel="noopener noreferrer">
                      <i className='fab fa-twitter fa-2x hf-text-blue'/>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="https://www.instagram.com/ldavidsp.ni/" target='_blank'
                       rel="noopener noreferrer">
                      <i className='fab fa-instagram fa-2x hf-text-blue'/>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="https://www.linkedin.com/in/ldavidsp/" target='_blank'
                       rel="noopener noreferrer">
                      <i className='fab fa-linkedin fa-2x hf-text-blue'/>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="https://github.com/ldavidsp" target='_blank' rel="noopener noreferrer">
                      <i className='fab fa-github fa-2x hf-text-blue'/>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="mailto:luisnic20@gmail.com" target='_blank' rel="noopener noreferrer">
                      <i className='fad fa-envelope-open fa-2x hf-text-blue'/>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
    );
  }
}

