import React, {Component} from 'react';
import Navbar from "../../ui/navigation/Navbar";
import {Helmet} from "react-helmet";
import Footer from "../../ui/Footer";
import HeaderPricing from "./HeaderPricing";
import translations from "../../../i18n/translate/translations";
import {language} from "../../../widget/functions";
import {FormattedMessage} from "react-intl";

export default class Pricing extends Component {

	componentDidMount() {
		window.addEventListener('DOMContentLoaded', () => {
			document.getElementById('preloader').classList.add('d-none')
		});
	}

	render() {
		return (
			<div className={'page-content-site path-pricing'}>
				<Helmet>
					<title>{translations[language()]['price.title']}</title>
					<link rel="canonical" href="https://homeflowapp.com/pricing"/>
					<link rel="alternate" hrefLang="x-default" href="https://homeflowapp.com/pricing"/>
					<body className='path-pricing'/>
				</Helmet>

				<Navbar/>
				<HeaderPricing/>

				<div className='container-fluid mt-5 pt-1 mb-3 path-pricing-content'>
					<div className="container">
						<h1 className='text-center font-weight-bold header-title mb-4'>
							<FormattedMessage id={'price.plan.title'}/>
						</h1>
						<div className="row mb-3 justify-content-center">
							<div className="mb-4 col-md-6 col-lg-4">
								<div className='homeflow-card text-center m-lg-4'>
									<div className="card-header">
										<h4 className="my-0 font-weight-bold">
											<FormattedMessage id={'price.plan.standard.name'}/>
										</h4>
									</div>
									<div className="card-body">
										<h1 className="card-title pricing-card-title hf-text-blue display-4 font-weight-700">
											<FormattedMessage id={'price.plan.standard.price'}/>
										</h1>
										<p className="text-dark font-weight-700">
											<FormattedMessage id={'price.plan.standard.time'}/>
										</p>

										<div className='justify-content-center d-flex'>
											<ul className="list-unstyled mt-3 mb-4 text-left">
												<li><i className={'far fa-check hf-blue'}/> <FormattedMessage id={'price.plan.standard.clients'}/></li>
												<li><i className={'far fa-check hf-blue'}/> <FormattedMessage id={'price.details.credits'}/>
												</li>
												<li><i className={'far fa-check hf-blue'}/> <FormattedMessage id={'price.details.quotes'}/></li>
												<li><i className={'far fa-check hf-blue'}/> <FormattedMessage id={'price.details.movil'}/></li>
												<li><i className={'far fa-check hf-blue'}/> Respaldo en la nube</li>
											</ul>
										</div>
									</div>

									<div className="card-footer bg-white">
										{/*<button type="button" className="btn btn-lg btn-block btn-outline-primary font-weight-bold">
											<FormattedMessage id={'price.plan.standard.create'}/> <i className="fas fa-long-arrow-right fa-lg ml-2"/>
										</button>*/}
									</div>
								</div>
							</div>
							<div className="mb-4 col-md-6 col-lg-4">
								<div className='homeflow-card text-center m-lg-4'>
									<div className="card-header">
										<h4 className="my-0 font-weight-bold">
											<FormattedMessage id={'price.plan.plus.name'}/>
										</h4>
									</div>
									<div className="card-body">
										<h1 className="card-title pricing-card-title hf-text-blue display-4 font-weight-700">
											<FormattedMessage id={'price.plan.plus.price'}/>
										</h1>
										<p className="text-dark font-weight-700">
											<FormattedMessage id={'price.plan.plus.time'}/>
										</p>

										<div className='justify-content-center d-flex'>
											<ul className="list-unstyled mt-3 mb-4 text-left">
												<li><i className={'far fa-check hf-blue'}/> <FormattedMessage id={'price.plan.plus.clients'}/>
												</li>
												<li><i className={'far fa-check hf-blue'}/> <FormattedMessage id={'price.details.credits'}/>
												</li>
												<li><i className={'far fa-check hf-blue'}/> <FormattedMessage id={'price.details.quotes'}/></li>
												<li><i className={'far fa-check hf-blue'}/> <FormattedMessage id={'price.details.movil'}/></li>
												<li><i className={'far fa-check hf-blue'}/> Respaldo en la nube</li>
											</ul>
										</div>
									</div>

									<div className="card-footer bg-white">
										{/*<button type="button" className="btn btn-lg btn-block btn-outline-primary font-weight-bold">
											<FormattedMessage id={'price.plan.plus.create'}/> <i className="fas fa-long-arrow-right fa-lg ml-2"/>
										</button>*/}
									</div>
								</div>
							</div>
							<div className="mb-4 col-md-6 col-lg-4">
								<div className='homeflow-card text-center m-lg-4'>
									<div className="card-header">
										<h4 className="my-0 font-weight-bold">
											<FormattedMessage id={'price.plan.premium.name'}/>
										</h4>
									</div>
									<div className="card-body">
										<h1 className="card-title pricing-card-title hf-text-blue display-4 font-weight-700">
											<FormattedMessage id={'price.plan.premium.price'}/>
										</h1>
										<p className="text-dark font-weight-700">
											<FormattedMessage id={'price.plan.premium.time'}/>
										</p>

										<div className='justify-content-center d-flex'>
											<ul className="list-unstyled mt-3 mb-4 text-left">
												<li><i className={'far fa-check hf-blue'}/> <FormattedMessage
													id={'price.plan.premium.clients'}/></li>
												<li><i className={'far fa-check hf-blue'}/> <FormattedMessage id={'price.details.credits'}/>
												</li>
												<li><i className={'far fa-check hf-blue'}/> <FormattedMessage id={'price.details.quotes'}/></li>
												<li><i className={'far fa-check hf-blue'}/> <FormattedMessage id={'price.details.movil'}/></li>
												<li><i className={'far fa-check hf-blue'}/> Notificaciones</li>
												<li><i className={'far fa-check hf-blue'}/> Respaldo en la nube</li>
											</ul>
										</div>
									</div>

									<div className="card-footer bg-white">
										{/*<button type="button" className="btn btn-lg btn-block btn-outline-primary font-weight-bold">
											<FormattedMessage id={'price.plan.premium.create'}/> <i className="fas fa-long-arrow-right fa-lg ml-2"/>
										</button>*/}
									</div>
								</div>
							</div>
							<div className="mb-4 col-md-6 col-lg-5">
								<div className='homeflow-card text-center m-lg-4'>
									<div className="card-header">
										<h4 className="my-0 font-weight-bold">
											<FormattedMessage id={'price.plan.enterprise.name'}/>
										</h4>
									</div>
									<div className="card-body">
										<h1 className="card-title pricing-card-title hf-text-blue display-4 font-weight-700">
											$29.99
										</h1>
										<p className="text-dark font-weight-700">
											mensual por miembro
										</p>

										<div className='justify-content-center d-flex'>
											<ul className="list-unstyled mt-3 mb-4 text-left">
												<li><i className={'far fa-check hf-blue'}/> Crear multiples rutas</li>
												<li><i className={'far fa-check hf-blue'}/> <FormattedMessage id={'price.details.clients'}/></li>
												<li><i className={'far fa-check hf-blue'}/> <FormattedMessage id={'price.details.credits'}/>
												</li>
												<li><i className={'far fa-check hf-blue'}/> <FormattedMessage id={'price.details.quotes'}/></li>
												{/*<li><i className={'far fa-check hf-blue'}/> <FormattedMessage id={'price.details.graphic'}/></li>*/}
												<li><i className={'far fa-check hf-blue'}/> <FormattedMessage id={'price.details.movil'}/></li>
												<li><i className={'far fa-check hf-blue'}/> Invitar a mas de 1 usuario</li>
												<li><i className={'far fa-check hf-blue'}/> Notificaciones</li>
												<li><i className={'far fa-check hf-blue'}/> Reporte de Recuperación</li>
												<li><i className={'far fa-check hf-blue'}/> Reporte de Saldos Cartera</li>
												<li><i className={'far fa-check hf-blue'}/> Reporte de Desembolsos</li>
												<li><i className={'far fa-check hf-blue'}/> Reporte de Créditos Cancelados</li>
												<li><i className={'far fa-check hf-blue'}/> Reporte de Créditos Vencidos</li>
												<li><i className={'far fa-check hf-blue'}/> Reporte de Créditos por Vencer</li>
												<li><i className={'far fa-check hf-blue'}/> Respaldo en la nube</li>
											</ul>
										</div>
									</div>

									<div className="card-footer bg-white">
										<a href={'mailto:support@homeflowapp.com'} className="btn btn-lg btn-block btn-primary font-weight-bold">
											Contactanos
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<Footer/>
			</div>
		);
	}
}

