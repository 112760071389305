import React, {Component} from "react";
import media from '../../../../i18n/media/media.json'
import {FormattedMessage} from "react-intl";
import {assets} from "../../../../widget/assets";

export default class Descriptions extends Component {
	render() {
		return (
			<div className='container-fluid mt-4 bg-light'>
				<div className='container'>
					<br/>
					<h1 className='font-weight-bold text-center section-title mt-3'>
						<FormattedMessage id={'comments.title'}/>
					</h1>
					<div className='row justify-content-center mt-5'>

						<div className='col-md-6 col-lg-4 d-flex justify-content-center mb-4'>
							<div className="homeflow-card m-2 p-4 border-0 card-deck justify-content-center">
								<div className='card-header p-0 border-0'>
									<div className='user-photo-enterprise'>
										<img src={media.comments.je} alt="..."/>
										<h4 className="card-title text-center font-weight-bold mt-3">
											<FormattedMessage id={'comments.clients.0.name'}/>
										</h4>
										<h6 className="card-subtitle mb-2 text-muted text-center">
											<FormattedMessage id={'comments.clients.0.enterprise'}/>
										</h6>
									</div>
								</div>
								<div className="card-body p-0 p-md-1 pt-2">
									<p className="card-text header-subtitle-2 hf-text-gray text-center">
										<strong className='hf-text-blue'>@Homeflow </strong> <FormattedMessage id={'comments.clients.0.description'}/>
									</p>
								</div>
							</div>
						</div>

						<div className='col-md-6 col-lg-4 d-flex justify-content-center mb-4'>
							<div className="homeflow-card m-2 p-4 border-0 card-deck justify-content-center">
								<div className='card-header p-0 border-0'>
									<div className='user-photo-enterprise'>
										<img src={media.comments.mi} alt="..."/>
										<h4 className="card-title text-center font-weight-bold mt-3">
											<FormattedMessage id={'comments.clients.1.name'}/>
										</h4>
										<h6 className="card-subtitle mb-2 text-muted text-center">
											<FormattedMessage id={'comments.clients.1.enterprise'}/>
										</h6>
									</div>
								</div>
								<div className="card-body p-0 p-md-1 pt-2">
									<p className="card-text header-subtitle-2 hf-text-gray text-center">
										<strong className='hf-text-blue'>@Homeflow </strong> <FormattedMessage id={'comments.clients.1.description'}/>
									</p>
								</div>
							</div>
						</div>

						{/*<div className='col-md-6 col-lg-4 d-flex justify-content-center mb-4'>
							<div className="homeflow-card m-2 p-4 border-0 card-deck justify-content-center">
								<div className='card-header p-0 border-0'>
									<div className='user-photo-enterprise'>
										<img src={media.comments.ma} alt="..."/>
										<h4 className="card-title text-center font-weight-bold mt-3">
											<FormattedMessage id={'comments.clients.2.name'}/>
										</h4>
										<h6 className="card-subtitle mb-2 text-muted text-center">
											<FormattedMessage id={'comments.clients.2.enterprise'}/>
										</h6>
									</div>
								</div>
								<div className="card-body p-0 p-md-1 pt-2">
									<p className="card-text header-subtitle-2 hf-text-gray text-center">
										<strong className='hf-text-blue'>@Homeflow </strong> <FormattedMessage id={'comments.clients.2.description'}/>
									</p>
								</div>
							</div>
						</div>*/}

						<div className='col-md-6 col-lg-4 d-flex justify-content-center mb-4'>
							<div className="homeflow-card m-2 p-4 border-0 card-deck justify-content-center">
								<div className='card-header p-0 border-0'>
									<div className='user-photo-enterprise'>
										<img src={assets(media.comments.lu)} alt="..."/>
										<h4 className="card-title text-center font-weight-bold mt-3">
											<FormattedMessage id={'comments.clients.3.name'}/>
										</h4>
										<h6 className="card-subtitle mb-2 text-muted text-center">
											<FormattedMessage id={'comments.clients.3.enterprise'}/>
										</h6>
									</div>
								</div>
								<div className="card-body p-0 p-md-1 pt-2">
									<p className="card-text header-subtitle-2 hf-text-gray text-center">
										<strong className='hf-text-blue'>@Homeflow </strong> <FormattedMessage id={'comments.clients.3.description'}/>
									</p>
								</div>
							</div>
						</div>

						<div className='col-md-6 col-lg-4 d-flex justify-content-center mb-4'>
							<div className="homeflow-card m-2 p-4 border-0 card-deck justify-content-center">
								<div className='card-header p-0 border-0'>
									<div className='user-photo-enterprise'>
										<img src={assets(media.comments.as)} alt="..."/>
										<h4 className="card-title text-center font-weight-bold mt-3">
											<FormattedMessage id={'comments.clients.4.name'}/>
										</h4>
										<h6 className="card-subtitle mb-2 text-muted text-center">
											<FormattedMessage id={'comments.clients.4.enterprise'}/>
										</h6>
									</div>
								</div>
								<div className="card-body p-0 p-md-1 pt-2">
									<p className="card-text header-subtitle-2 hf-text-gray text-center">
										<strong className='hf-text-blue'>@Homeflow </strong> <FormattedMessage id={'comments.clients.4.description'}/>
									</p>
								</div>
							</div>
						</div>

					</div>
				</div>
			</div>
		)
	}
}

