import React, {Component} from 'react'
import classNames from "classnames";
import {Link} from "react-router-dom";
import {FormattedMessage} from "react-intl";
import media from '../../i18n/media/media.json'
import {assets} from "../../widget/assets";

export default class Footer extends Component {

	render() {
		return (
			<footer className="footer mt-0" role="contentinfo">
				<div className='container-fluid bg-gray-darkest test-free'>
					<div className='collaborate'>
						<div className='container'>
							<div className="row justify-content-center align-items-center flex-column">
								<h1 className='font-weight-bold text-white section-title text-center'>
									<FormattedMessage id={'footer.test'}/>
								</h1>

								<div className='container d-flex justify-content-center align-items-center'>
									<a
										href={media.footer.create}
										rel='noreferrer noopener'
										className="btn btn-google mt-4 d-none d-md-block"
										style={{height: '49px', lineHeight: '2.1'}}>
                    <span className="font-weight-700 text-dark">
                      <i className="fas fa-plug"/> <FormattedMessage id={'footer.create'}/> <i className="fas fa-long-arrow-right"/>
                    </span>
									</a>

									<a
										aria-label={'Homeflow Business'}
										rel="noopener noreferrer"
										className='ml-md-4 ml-md-6 mt-4'
										href='https://play.google.com/store/apps/details?id=com.homeflowenterprise'
										target={'_blank'}>
										<img src={assets(media.home.store)} style={{height: '49px'}} alt="" className="img"/>
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className='container-fluid bg-gray-darkest'>
					<div className='container'>
					<div className='row justify-content-center'>
						<div className='col-md-3 my-auto d-flex justify-content-center'>
							<img src={assets(media.footer.logo)} className='w-100' alt={'Homeflow'}/>
						</div>

						<div className='col-md-6 my-auto'>
							<div className="col-12 mt-3">
								<ul className="nav justify-content-center" style={{zIndex: '9'}}>
									<li className="nav-item">
										<a className="nav-link active" href="https://www.facebook.com/homeflowapp" target={'_blank'} rel="noopener noreferrer">
											<i className="fab fa-facebook fa-lg hf-text-blue"/>
										</a>
									</li>

									<li className="nav-item">
										<a className="nav-link" href="https://twitter.com/HFlowBusiness" target={'_blank'} rel="noopener noreferrer">
											<i className="fab fa-twitter fa-lg hf-text-blue"/>
										</a>
									</li>

									<li className="nav-item">
										<a
											className="nav-link"
											href="https://www.instagram.com/homeflow.app"
											target={'_blank'}
											rel="noopener noreferrer">
											<i className="fab fa-instagram fa-lg hf-text-blue"/>
										</a>
									</li>
								</ul>
							</div>

							<p className="text-center text-gray-light font-weight-500 mt-2">
								2019 - {new Date().getFullYear()} <i className="fas fa-copyright fa-lg"/> Homeflow | Todos los derechos
								reservados.
							</p>

							<div className='container'>
								<hr className="text-center w-100"/>
								<p className="text-center text-gray-light font-weight-600">
									<span>Desarrollado por <a href="https://www.linkedin.com/in/ldavidsp/" className={'hf-text-blue'} target={'_blank'} rel="noopener noreferrer">Luis Sol&oacute;zano</a></span>
								</p>
							</div>
						</div>

						<div className='col-md-3 my-auto d-none d-md-flex justify-content-center'>
							<ul className="nav flex-column">
								<p className='font-weight-bold h5 font-weight-bold text-gray-light' style={{marginLeft: '15px'}}>Homeflow</p>

								<li className="nav-item">
									<Link
										to={'/apps'}
										className={classNames({
											'nav-link font-weight-500 text-gray-light hover-gray-100': true
										})}>
										App Cobranzas
									</Link>
								</li>

								<li className="nav-item">
									<Link
										to={'/solutions'}
										className={classNames({
											'nav-link text-gray-light font-weight-500 hover-gray-100': true
										})}>
										Soluciones
									</Link>
								</li>

								{/*<li className="nav-item">
									<Link
										to={'/marketplace'}
										className={classNames({
											'nav-link font-weight-500 text-gray-light hover-gray-100': true
										})}>
										<i className="fad fa-store-alt mr-3"/> Marketplace
									</Link>
								</li>*/}

								<li className="nav-item">
									<Link
										to={'/pricing'}
										className={classNames({
											'nav-link font-weight-500 text-gray-light hover-gray-100': true
										})}>
										Precios
									</Link>
								</li>
							</ul>
						</div>
					</div>
				</div>
				</div>
			</footer>
		)
	}

}

