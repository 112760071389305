import React, {Component} from 'react';
import Navbar from "../../ui/navigation/Navbar";
import HeaderSolutions from "./HeaderSolutions";
import {Helmet} from "react-helmet";
import Footer from "../../ui/Footer";
import translations from "../../../i18n/translate/translations";
import {language} from "../../../widget/functions";

export default class Solutions extends Component {

  componentDidMount() {
    window.addEventListener('DOMContentLoaded', () => {
      document.getElementById('preloader').classList.add('d-none')
    });
  }

  render() {
    return (
      <div className={'page-content-site'}>
        <Helmet>
          <title>{translations[language()]['solutions.title']}</title>
          <link rel="canonical" href="https://homeflowapp.com/solutions"/>
          <link rel="alternate" hrefLang="x-default" href="https://homeflowapp.com/solutions"/>
          <body className='path-solutions'/>
        </Helmet>

        <Navbar/>
        <HeaderSolutions/>

        {/*<div className='container-fluid mt-5 pt-3 mb-5'>
          <div className='container'>
            <h1 className='font-weight-bold section-title text-center w-100'>
              <FormattedMessage id={'solutions.section1.title'}/>
            </h1>

            <hr className='mb-5 w-50 border-0'/>

            <ul className="list-stack mb-5">
              <li>
                <p className="stack-link d-flex align-items-center text-decoration-none" title="Desarrollo en PHP">
                  <img loading="lazy" src={assets(media.solutions.php)} alt="Desarrollo en PHP" height="60px" width="60px"/>
                  <span className='text-dark h1'>PHP</span>
                </p>
              </li>
              <li>
                <p className="stack-link d-flex align-items-center text-decoration-none" title="Desarrollo en React.js">
                  <img loading="lazy" src={assets(media.solutions.react)} alt="Desarrollo en React.js" height="60px" width="60px"/>
                  <span className='text-dark h1'>React.js</span>
                </p>
              </li>
              <li>
                <p className="stack-link d-flex align-items-center text-decoration-none" title="Desarrollo en Drupal">
                  <img loading="lazy" src={assets(media.solutions.drupal)} alt="Desarrollo en Drupal" height="60px" width="60px"/>
                  <span className='text-dark h1'>Drupal</span>
                </p>
              </li>

              <li>
                <p className="stack-link d-flex align-items-center text-decoration-none" title="Desarrollamos en Laravel">
                  <img loading="lazy" src={assets(media.solutions.laravel)} alt="Desarrollo en Laravel" height="60px" width="60px"/>
                  <span className='text-dark h1'>Laravel</span>
                </p>
              </li>
              <li>
                <p className="stack-link d-flex align-items-center text-decoration-none" title="Desarrollo en Node.js">
                  <img loading="lazy" src={assets(media.solutions.node)} alt="Desarrollo en Node.js" height="60px" width="60px"/>
                  <span className='text-dark h1'>Node.js</span>
                </p>
              </li>
              <li>
                <p className="stack-link d-flex align-items-center text-decoration-none" title="Desarrollo en Android">
                  <img loading="lazy" src={assets(media.solutions.android)} alt="Desarrollo en Android" height="60px" width="60px"/>
                  <span className='text-dark h1'>Android</span>
                </p>
              </li>

              <li>
                <p className="stack-link d-flex align-items-center text-decoration-none" title="Desarrollo en Symfony">
                  <img loading="lazy" src={assets(media.solutions.symfony)} alt="Desarrollo en Symfony" height="60px" width="60px"/>
                  <span className='text-dark h1'>Symfony</span>
                </p>
              </li>
              <li>
                <p className="stack-link d-flex align-items-center text-decoration-none" title="Desarrollo en Electron.js">
                  <img loading="lazy" src={assets(media.solutions.electron)} alt="Desarrollo en Electron.js" height="60px" width="60px"/>
                  <span className='text-dark h1'>Electron.js</span>
                </p>
              </li>
              <li>
                <p className="stack-link d-flex align-items-center text-decoration-none" title="Desarrollo en Kotlin">
                  <img loading="lazy" src={assets(media.solutions.kotlin)} alt="Desarrollo en Kotlin" height="60px" width="60px"/>
                  <span className='text-dark h1'>Kotlin</span>
                </p>
              </li>
            </ul>
          </div>
        </div>*/}

	      <Footer/>
      </div>
    );
  }
}

