import React, { Component } from 'react'
import media from '../../../i18n/media/media.json'
import {FormattedMessage} from "react-intl";
import {assets} from "../../../widget/assets";

export default class Header extends Component {

  render () {
    return (
      <div className='homeflow--banner'>
        <div className='homeflow--hero pb-5'>
          <div className={'container pt-5'}>
            <div className={'row flex-lg-row-reverse'}>
              <div className={'col-md-12 col-lg-5 homeflow--home-devices my-auto'}>
                <img src={assets(media.home.startup)} className="img-header mt-5 mt-lg-0" alt="..."/>
              </div>
              <div className='col-md-12 col-lg-7 homeflow--home-title'>
                <div className='d-flex justify-content-center justify-content-lg-start flex-column align-items-center align-items-lg-start'>
                  <h1 className='pr-md-4 header-title-h2 mt-5 mt-md-3 zoomIn animated font-weight-bold text-dark text-center text-lg-left'>
                    <FormattedMessage id="enterprise.header.title"/>
                  </h1>

                  <p className="pr-lg-5 header-subtitle-3 text-muted mt-5 text-center text-lg-left">
                    <FormattedMessage id="enterprise.header.subtitle"/>
                  </p>

                  <div className='input-group mt-5 pt-2 align-items-lg-center justify-content-lg-start align-items-center justify-content-center'>
                    <a
                      href={media.home.access}
                      target={'_blank'}
                      rel='noopener noreferrer'
                      style={{height: '49px'}}
                      className="btn btn-google pl-2 pr-2 d-none d-lg-flex ">
                      <span className="ml-2 mr-2 font-weight-700 text-dark">
                        <FormattedMessage id="homepage.header.access"/>
                        <i className="fad fa-long-arrow-right text-primary ml-2 fa-lg"/>
                      </span>
                    </a>

                    <a
                      aria-label={'Homeflow Business'}
                      rel="noopener noreferrer"
                      className='ml-md-3'
                      href='https://play.google.com/store/apps/details?id=com.homeflowenterprise'
                      target={'_blank'}>
                      <img src={assets(media.home.store)} style={{height: '49px'}} alt="" className="img"/>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <span id={'whyhomeflow'}/>
      </div>
    )
  }
}

